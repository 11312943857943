@import "core";

.calendar-view-wrapper {
  .calendar-explanation-banner {
    position: relative;
    padding: 15px 20px 10px 20px;
    margin: 16px 0 32px 0px;
    z-index: 1;
    border: 1px solid var(--theme-color-primary);
    stroke-opacity: 0.5;
    border-radius: 10px;
    display: flex;

    .texts {
      padding-left: 20px;
      .title {
        color: color(gray, 700);
        font-family: Nunito, serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        margin-right: 8px;
      }

      .subtitle {
        color: color(gray, 700);
        font-family: Nunito, serif;
        font-size: 14px;
        line-height: 19px;
        padding-top: 8px;

        .orange {
          color: var(--theme-color-primary);
        }
      }
    }
  }
  .calendar-explanation-banner-posts-image {
    height: auto;
    width: 140px;
  }
  .calendar-explanation-banner-girl-image {
    width: 58px;
  }

  .calendar-header {
    position: relative;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid color(gray, 200);
    border-left: 1px solid color(gray, 200);
    border-top: 1px solid color(gray, 200);
    padding: 0 24px;

    &.brand-view {
      margin-bottom: 0;
    }

    .calendar-transparent-button {
      height: 40px;
      line-height: 2.4;
      border: 1px solid color(gray, 200);
      background-color: $color-white;
    }

    .left-content {
      display: flex;
      align-items: center;

      .button-transparent.today {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        min-width: 72px;
        border: 1px solid color(gray, 300);
        border-radius: 5px;
        background-color: $color-white;
        margin-right: 16px;
        color: color(gray, 700);
        font-family: Nunito, serif;
        font-size: 14px;
        font-weight: bold;
        /*
        &.active {
          background-color: var(--theme-color-primary);
          color: white;
        }
        */
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        margin: 0 4px 0 0;
      }

      .calendar-navigation {
        display: flex;
        align-items: center;
        margin-right: 24px;

        .button-navigation {
          display: flex;
          align-items: center;
          width: 28px;
          height: 28px;
          color: color(gray, 700);
          line-height: 40px;
          border: none;
          padding: 0 14px;
          margin: 0;
          cursor: pointer;

          span {
            mat-icon {
              font-size: 16px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .info-disclaimer {
        font-size: 14px;
        line-height: 24px;
        color: color(gray, 400);
        clear: both;

        .mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }
    }

    .right-content {
      display: flex;
      align-items: center;

      app-tooltip {
        margin-right: 32px;
      }

      .toggle {
        height: 40px;
        border: none;
        font-size: 14px;
        margin-right: 25px;
        color: color(gray, 700);
        line-height: 38px;
        padding: 0 10px;
        min-width: 220px;
        text-align: center;

        mat-icon {
          line-height: 30px;
          font-size: 18px;
          color: var(--theme-color-primary);
          margin-left: 8px;
        }

        &.active {
          color: $black;
          background-color: white;
        }
      }
    }
  }

  .calendar-selector {
    font-size: 14px;
    font-family: Nunito, serif;
    margin-bottom: 0;
    text-align: right;
    /*
        TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
        */
    /*
        TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
        */
    .mat-form-field-wrapper {
      padding-bottom: 0;
      /*
        TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
        */
      .mat-form-field-underline {
        display: none;
      }
      /*
        TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
        */
      .mat-form-field-infix {
        border-top: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      /*
        TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
        */
      .mat-select-arrow {
        color: var(--theme-color-primary);
      }
    }
  }

  .cal-month-view .cal-header {
    border-right: 1px solid color(gray, 200);
    border-left: 1px solid color(gray, 200);
    border-bottom: 1px solid color(gray, 200);
    background-color: color(gray, 200);
  }
}

.calendar-select-panel {
  border-radius: 5px;
  /*
        TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
        */
  .mat-select-content {
    .calendar-option-language,
    .calendar-option-calendar {
      height: 40px;
      color: color(gray, 700);
      font-size: 14px !important;
      font-family: Nunito, serif !important;
      font-weight: bold;
    }

    .calendar-option-language {
      color: white;
      background-color: color(gray, 700);
    }
  }
}

.button-one-click-publish-wrap {
  margin-left: 20px;
}
.button-one-click-publish {
  margin-left: 20px;
  text-transform: uppercase;
  height: 42px;
  color: $color-white;
  font-family: Nunito, serif;
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: var(--theme-color-primary);
  display: inline-block;
  margin: 0;
  padding: 0 1em;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  overflow: visible;
  line-height: 3;
  text-decoration: none;
  border-radius: 5px;
}
.button-one-click-publish--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.empty-event {
  height: 74px;
  width: 100%;
}
.cal-events {
  margin: 0 !important;
}
.cal-event {
  width: 100% !important;
}

.cal-tooltip {
  opacity: 1;
  border-radius: 10px;

  .cal-tooltip-inner {
    padding: 16px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
    max-width: none;
    border: 1px solid color(gray, 200);
    background-color: white;

    .title {
      display: flex;
      margin-bottom: 8px;
      h1 {
        margin: 0 8px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  &.cal-tooltip-left {
    transform: scale(0.7) translateX(100px);
  }

  &.cal-tooltip-right {
    transform: scale(0.7) translateX(-100px);
  }

  &.cal-tooltip-top {
    /* video posts are higher */
    transform: scale(0.7) translateY(80px);
  }

  &.cal-tooltip-bottom {
    transform: scale(0.7) translateY(-100px);
  }
}
.custom-cal-day-cell,
.cal-day-cell--outbound {
  height: 100%;
}

.fb-event-preview-logo {
  margin-right: 5px;
}

.cal-event:last-child {
  margin-bottom: 8px !important;
}

.leave-campaign {
  text-align: right;
}
